<template>
  <div>

    <!-- Course Header Block-->
    <div  style="position: relative; width : 100%">

      <!-- Course Background Image-->
      <v-img style="opacity: 0.7" class="" height="100vh" :src="entity.img" v-if="entity.img"  />
      <v-img style="opacity: 0.7" class="" height="100vh"  src="@/assets/img/course_blank.jpg" v-else />

      <!--  Course Details Desktop-->
      <div v-if="!SM"  :class="displayHide(['sm']) ? 'pa-6 mx-auto' : ''"
              style="position: absolute; width: 100%;  bottom: 50px;  ">

          <v-sheet  max-width="1100" color="#00000033"  dark class="px-10 pb-9 pt-16 mx-auto  " >
            <!-- Course Name and Price-->
            <div class="d-flex  justify-space-between">
              <h1 style="line-height: 1.2"> {{ entity.name }}</h1>
              <div v-if="entity.payed" >

                <div v-if="entity.has_payment_plans">
                  <div v-if="entity.discount">
                    <h2> {{ $t('priceFrom') }} {{ entity.min_price - Math.round(entity.discount/100*entity.min_price) }}  {{ BUSINESS_CURRENCY }} </h2>
                    <div class="d-flex text-no-wrap">
                      <h4 style="text-decoration: line-through" > {{ $t('priceFrom') }} {{ entity.min_price }} {{ BUSINESS_CURRENCY }}  </h4>
                      <h4 class="font-weight-bold ml-1"> ({{ $t('Discount')}} : {{ entity.discount }} %)</h4>
                    </div>
                  </div>
                  <h2 v-else> {{ $t('priceFrom') }}  {{ entity.min_price }} {{ BUSINESS_CURRENCY }}</h2>
                </div>
                <div v-else>
                  <div v-if="entity.discount">
                    <h2> {{ entity.price - Math.round(entity.discount/100*entity.price) }} {{ BUSINESS_CURRENCY }} </h2>
                    <div class="d-flex text-no-wrap">
                      <h5 style="text-decoration: line-through" > {{ entity.price }} {{ BUSINESS_CURRENCY }}  </h5>
                      <h5 class="font-weight-bold ml-1">  ({{ $t('Discount')}} : {{ entity.discount }} %)</h5>
                    </div>
                  </div>
                  <h2 v-else>{{ entity.price }} {{ BUSINESS_CURRENCY }}</h2>
                </div>
                 <h4> {{ priceDescriptionSubscriptionText }}</h4>
              </div>
              <h2 v-else> </h2>
            </div>
            <!-- Description and Enroll/Pay Button-->
            <div class="d-flex justify-space-between align-center"
                 :class="displayShow(['sm']) ? ' flex-wrap text-center' : '' " >
              <div>
                <h5 class="font-weight-light mt-3"> {{ entity.short_description }}</h5>
                <div class="d-flex flex-wrap mt-3 align-center">
                  <h5 >
                    {{ $t('Modules')}} : {{ entity.modules.length }}
                    <v-icon>mdi-circle-small</v-icon>
                    {{ $t('Topics')}} : {{ getTopicsLength()  }}
                    <v-icon>mdi-circle-small</v-icon>
                    {{ $t('Components')}} : {{ getComponentsLength() }}
                  </h5>
                </div>
              </div>
              <!-- Pay/Enroll Button-->
              <v-sheet class="d-flex mt-6 " color="transparent" >
                <view-button-course-enroll
                    :display="triggerDisplayEnroll"
                    :entity="entity"
                >
                  <v-btn @click="triggerDisplayEnroll++" class="mt-3 noCaps" large  outlined>
                    <span v-if="entity.enrolled">{{ $t('Goto') }}</span>
                    <span v-else-if="entity.payed">{{ purchaseText }}</span>
                    <span v-if="!entity.payed">{{ $t('Enroll') }} <v-icon>mdi-chevron-right</v-icon></span>
                  </v-btn>
                </view-button-course-enroll>

              </v-sheet>

            </div>
          </v-sheet>

        </div>
      <!-- Course Details Mobile-->
      <v-sheet v-else
               class="pa-3 fill-height"
               style="position: absolute; top:0; bottom: 0; width: 100%; !important;"
               color="#00000077"
               dark  >

        <v-row class="no-gutters flex-wrap flex-column fill-height">

          <!-- Course Name and Short Description-->
          <v-col cols="auto" class="grow"  >
            <div class="fill-height d-flex align-center justify-center" style="width: 100%">
            <div>
              <h4 style="line-height: 1.2" class="text-center" > {{ entity.name }}</h4>
              <h5 style="line-height: 1.2" class="text-center font-weight-light mt-3" > {{ entity.short_description }}</h5>
            </div>
            </div>
          </v-col>
          <!-- Course Price , Details, Enroll/Pay Button-->
          <v-col cols="auto" class="shrink" >
            <!-- Course Price-->
            <div class=" d-flex align-center" >
              <v-simple-table dense class="mt-3" style="background: transparent; width: 100%">
                <tbody>
                <tr v-if="entity.payed" style="background: transparent">
                  <td width="10px" class="text-no-wrap px-0" align="left" style="border: none">
                    <h5>{{$t('Price')}} :</h5>
                  </td>
                  <td align="right" style="border: none">
                    <h5 v-if="entity.discount">
                      {{ entity.has_payment_plans ? ( $t('priceFrom') + " " + (entity.min_price - Math.round(entity.discount/100*entity.min_price))  ) :  (entity.price - Math.round(entity.discount/100*entity.price))  }}  {{ BUSINESS_CURRENCY }}</h5>
                    <h5 v-if="!entity.discount">{{ entity.has_payment_plans ? ( $t('priceFrom') + " " + entity.min_price  ) :  entity.price  }}  {{ BUSINESS_CURRENCY }}</h5>
                  </td>
                </tr>
                <tr v-if="entity.payed && entity.discount" style="background: transparent">
                  <td width="10px" class="text-no-wrap px-0" align="left" style="border: none">
                    <h5>{{$t('OldPrice')}} :</h5>
                  </td>
                  <td align="right" style="border: none">
                    <h5 style="text-decoration: line-through">{{ entity.has_payment_plans ? ( $t('priceFrom') + " " + entity.min_price  ) :  entity.price  }}  {{ BUSINESS_CURRENCY }}</h5>
                  </td>
                </tr>
                <tr v-if="entity.payed && entity.discount" style="background: transparent">
                  <td width="10px" class="text-no-wrap px-0" align="left" style="border: none">
                    <h5>{{$t('Discount')}} :</h5>
                  </td>
                  <td align="right" style="border: none">
                    <h5>{{ entity.discount  }} %</h5>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </div>
            <div class="d-flex justify-space-between flex-wrap mt-3 mb-3 align-center" >
              <h6 class="text-center d-flex  align-center" style="width: 100%" >
                {{ $t('Modules')}} : {{ entity.modules.length }}
                <v-icon>mdi-circle-small</v-icon>
                {{ $t('Topics')}} : {{ getTopicsLength()  }}
                <v-icon>mdi-circle-small</v-icon>
                {{ $t('Components')}} : {{ getComponentsLength() }}
              </h6>
            </div>
            <div class="mt-3 mb-3">

              <view-button-course-enroll
                  :display="triggerDisplayEnroll"
                  :entity="entity"
              >
                <v-btn @click="triggerDisplayEnroll++" class="mt-3 noCaps" large block outlined>
                  <span v-if="entity.enrolled">{{ $t('Goto') }}</span>
                  <span v-else-if="entity.payed">{{ purchaseText }}</span>
                  <span v-if="!entity.payed">{{ $t('Enroll') }} <v-icon>mdi-chevron-right</v-icon></span>
                </v-btn>
              </view-button-course-enroll>

            </div>
          </v-col>

        </v-row>

      </v-sheet>

    </div>

    <!-- Course Content Description-->
    <v-sheet>
      <v-sheet :class="!SM ? 'section-content py-16 px-10' : 'px-3 py-6'">
        <div class="d-flex" :class="SM ? 'flex-wrap' : '' ">
          <div style="width: 100%" :class="SM ? '' : 'mr-13' ">
            <div>
              <h1 v-if="displayHide(['sm'])" style="line-height: 1.2"  class="font-weight-light ">{{ $t('CourseDescription') }}</h1>
              <h3 v-else style="line-height: 1.2"  class="font-weight-light text-center">{{ $t('CourseDescription') }}</h3>
            </div>
            <ws-text-viewer
                :value="entity.description"
                :html="entity.description_text"
                isPublic
                class="ma-3"
            />
          </div>
          <!-- SIDEBAR INFORMATION -->
          <v-sheet color="transparent" :min-width="displayShow(['sm']) ? '100%' : '400' "
                   :class="displayShow(['sm']) ? 'mt-6' : '' ">

            <h1 v-if="displayHide(['sm'])" style="line-height: 1.2"  class="font-weight-light mb-6">{{ $t('CourseContent') }}</h1>
            <h3 v-else style="line-height: 1.2"  class="font-weight-light mb-6 text-center">{{ $t('CourseContent') }}</h3>

            <v-sheet class=""  color="transparent" v-for="(module,i) in entity.modules" :key="`module${i}`">

              <h2 v-if="displayHide(['sm'])" style="line-height: 1.2" class="font-weight-light mb-3 d-flex align-center"> {{ module.name }} </h2>
              <h4 v-else style="line-height: 1.2" class="font-weight-light mb-3 text-center"> {{ module.name }} </h4>

              <v-sheet  outlined class="wsRoundedHalf pb-3 mb-3" >
                <v-simple-table style="background: transparent" dense>
                  <tbody>

                  <template v-for="(topic,j) in module.topics">
                    <tr style="background: transparent" :key="`topic${i}${j}`">
                      <td colspan="2" class="px-0 " style="border: none">
                        <h3 v-if="displayHide(['sm'])" class="font-weight-medium text-center py-3">{{ topic.name }}</h3>
                        <h4 v-else class="font-weight-medium text-center py-3">{{ topic.name }}</h4>
                      </td>
                    </tr>
                    <tr class="pointer" v-for="(component,k) in topic.components" :key="`component${i}${j}${k}`" >
                      <td width="10px" style="border: none" class="px-0 pl-5">
                        <v-icon size="22"  > {{ getEntityTypeIcon(component.entity_type ) }}</v-icon>
                      </td>
                      <td style="border: none" class="pr-5">
                        <h4 class="font-weight-light" v-html="component.name"></h4>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </v-simple-table>
              </v-sheet>


            </v-sheet>
          </v-sheet>
        </div>
      </v-sheet>
    </v-sheet>

    <!-- :: DIALOGS :: -->
    <!-- Enrollment Success Dialog on Course -->
    <wsDialog v-model="displayEnrollSuccess"
              :loading="$store.state.LOADING"
              @save="goToCourse"
              :title="displayApplicationReceivedText ? $t('CourseApplicationSuccess') : $t('EnrollmentSuccess')"
              :cancel-text="$t('Close')"
              :show-save="!displayApplicationReceivedText"
              :saveText="!displayApplicationReceivedText ? $t('GotoCourse') : $t('Ok')">

      <h5 class="font-weight-medium text-center mb-3">{{ displayApplicationReceivedText ?   $t('CourseApplicationSuccessMessage') : $t('EnrollmentSuccessMessage') }}</h5>

    </wsDialog>

    <!-- Enroll on Course -->
    <wsDialog v-model="displayEnroll"
              width="700"
              :loading="$store.state.LOADING"
              @save="enrollOnCourse"
              :title="$t('EnrollOnCourse')"
              :saveText="$t('Enroll')">

      <!-- Scheduled course -->
      <div v-if="entity.scheduled">
        <div v-if="!SM">
          <h5 class="font-weight-medium mb-3">{{ $t('ChooseEnrollmentSchedule') }}:</h5>
          <v-simple-table >
            <thead>
            <tr>
              <th></th>
              <th></th>
              <th :style="`color : ${wsDARK}`"> {{ $t('Education Start') }}</th>
              <th :style="`color : ${wsDARK}`"> {{ $t('Education End') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(wave,i) in enrollmentWaves" :key="i"
                @click="enrollmentData.enrollment_wave_id = wave.uuid"
                class="pointer">
              <td>
                <v-btn @click="enrollmentData.enrollment_wave_id = wave.uuid"
                       :color="enrollmentData.enrollment_wave_id === wave.uuid ?  'green lighten-2' : 'grey' "
                       small icon
                       class="mt-1">
                  <v-icon small>{{ enrollmentData.enrollment_wave_id === wave.uuid ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'}}</v-icon>
                </v-btn>
              </td>
              <td>
                <h5>{{ wave.name }}</h5>
              </td>

              <td>
                <v-chip class="my-1 mr-2" small dark :color="wsDARK"  >
                  <v-icon small class="mr-1" > mdi-calendar</v-icon>
                  <h4 class="font-weight-medium">{{wave.education_start }}</h4>
                </v-chip>
              </td>
              <td>
                <v-chip class="my-1 mr-2" small outlined   >
                  <v-icon small class="mr-1" > mdi-calendar</v-icon>
                  <h4 class="font-weight-medium">{{wave.education_end }}</h4>
                </v-chip>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </div>
        <div v-else>
          <h5 class="font-weight-medium mb-3 mt-6 text-center">{{ $t('ChooseEnrollmentSchedule') }}:</h5>
          <v-sheet  v-for="(wave,i) in enrollmentWaves" :key="i"
                    @click="enrollmentData.enrollment_wave_id = wave.uuid"
                    :dark="enrollmentData.enrollment_wave_id === wave.uuid"
                    :color="enrollmentData.enrollment_wave_id === wave.uuid ? wsACCENT : ''"
                    class="pointer pa-3 mt-3 wsRoundedHalf" outlined>
            <h5 class="text-center">{{ wave.name }}</h5>
            <div class="d-flex justify-center">
              <div>
                <ws-chip icon="mdi-calendar" class="my-1 mr-2" small dark :color="enrollmentData.enrollment_wave_id !== wave.uuid ? wsACCENT : ''"  >
                  <h5 class="font-weight-medium">{{ $t('EducationStart') }}: {{wave.education_start }}</h5>
                </ws-chip>
                <ws-chip icon="mdi-calendar" class="my-1 mr-2" small dark :color="enrollmentData.enrollment_wave_id !== wave.uuid ? wsACCENT : ''"  >
                  <h5 class="font-weight-medium">{{ $t('EducationEnd') }}: {{wave.education_end }}</h5>
                </ws-chip>
              </div>

            </div>

          </v-sheet>
        </div>

      </div>


    </wsDialog>
    <!-- Course Purchase -->
    <wsDialog v-model="displayPurchase"
              width="900"
              :loading="$store.state.LOADING"
              @save="purchaseCourse"
              :title="$t('PurchaseCourse')"
              :saveText="purchaseText">

      <div class="pb-6">
        <div v-if="!SM">
          <v-simple-table >
            <tbody>
            <tr style="background: transparent" class="pointer"  >
              <td class="px-0 pl-3 py-3" width="1">
                <v-img v-if="entity.img" class="wsRoundedHalf" height="120" width="200"  :src="entity.img" ></v-img>
                <v-img v-else class="wsRoundedHalf" height="100" width="200"  src="@/assets/img/course_blank.jpg" ></v-img>
              </td>
              <td valign="top" class="py-3">
                <h4>{{ entity.name }}</h4>
                <h5 class="font-weight-light mt-3">
                  {{ entity.short_description }}
                </h5>
              </td>
              <td valign="top" class="py-3" align="right"  width="150px">

                <h4 class="text-no-wrap" :style="`color: ${wsDARK}`">{{ singlePriceText }} </h4>
                <h5> {{ priceDescriptionSubscriptionText }}</h5>

              </td>
            </tr>
            </tbody>
          </v-simple-table>
          <v-divider class="my-3" />
          <h4 class="text-right">{{ $t('Total') }}: {{ totalPriceText }} </h4>
        </div>
        <!-- Mobile View-->
        <div v-else>

          <v-img v-if="entity.img" class="wsRoundedHalf" height="180"   :src="entity.img" ></v-img>
          <v-img v-else class="wsRoundedHalf" height="180"   src="@/assets/img/course_blank.jpg" ></v-img>

          <h4 class="mt-3">{{ entity.name }}</h4>
          <v-divider class="my-1"></v-divider>
          <h4 class="text-right font-weight-light">{{ $t('Total') }}: <span class="font-weight-bold">{{ totalPriceText }} </span></h4>

        </div>

        <!-- Subscription course -->
        <div  class="mt-6 " v-if="entity.is_subscription">
          <h4 :class="[{'text-center' : SM}]" class="mb-5" >{{ $t('ChooseSubscription') }}</h4>
          <ws-select :items="timePeriodsSelect" v-model="enrollmentData.quantity" :placeholder="$t('Period')">  </ws-select>
        </div>

        <!-- Payment Plans course -->
        <div v-if="entity.has_payment_plans">
          <h5 class="font-weight-medium my-3">{{ $t('ChoosePaymentPlan') }}:</h5>
          <v-simple-table>
            <tbody>
            <tr v-for="(plan,i) in entity.payment_plans" :key="i"
                @click="enrollmentData.payment_plan_id = plan.value"
                class="pointer">
              <td width="10px">
                <v-btn @click="enrollmentData.payment_plan_id = plan.value"
                       :color="enrollmentData.payment_plan_id === plan.value ?  'green lighten-2' : 'grey' "
                       small icon
                       class="mt-1">
                  <v-icon small>{{ enrollmentData.payment_plan_id === plan.value ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'}}</v-icon>
                </v-btn>
              </td>
              <td>
                <h5>{{ plan.text }}</h5>
              </td>

              <td width="10px" class="text-no-wrap" align="center">
                <h4>{{ plan.price }}  {{ BUSINESS_CURRENCY }} </h4>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </div>

        <!-- Scheduled course -->
        <div v-if="entity.online && entity.scheduled">
          <div v-if="!SM">
            <h5 class="font-weight-medium mt-3">{{ $t('ChooseEnrollmentSchedule') }}:</h5>
            <v-simple-table >
              <thead>
              <tr>
                <th></th>
                <th></th>
                <th :style="`color : ${wsDARK}`"> {{ $t('Education Start') }}</th>
                <th :style="`color : ${wsDARK}`"> {{ $t('Education End') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(wave,i) in enrollmentWaves" :key="i"
                  @click="enrollmentData.enrollment_wave_id = wave.uuid"
                  class="pointer">
                <td>
                  <v-btn @click="enrollmentData.enrollment_wave_id = wave.uuid"
                         :color="enrollmentData.enrollment_wave_id === wave.uuid ?  'green lighten-2' : 'grey' "
                         small icon
                         class="mt-1">
                    <v-icon small>{{ enrollmentData.enrollment_wave_id === wave.uuid ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'}}</v-icon>
                  </v-btn>
                </td>
                <td>
                  <h5>{{ wave.name }}</h5>
                </td>

                <td>
                  <v-chip class="my-1 mr-2" small dark :color="wsDARK"  >
                    <v-icon small class="mr-1" > mdi-calendar</v-icon>
                    <h4 class="font-weight-medium">{{wave.education_start }}</h4>
                  </v-chip>
                </td>
                <td>
                  <v-chip class="my-1 mr-2" small outlined   >
                    <v-icon small class="mr-1" > mdi-calendar</v-icon>
                    <h4 class="font-weight-medium">{{wave.education_end }}</h4>
                  </v-chip>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </div>
          <div v-else>
            <h5 class="font-weight-medium mb-3 mt-6 text-center">{{ $t('ChooseEnrollmentSchedule') }}:</h5>
            <v-sheet  v-for="(wave,i) in enrollmentWaves" :key="i"
                      @click="enrollmentData.enrollment_wave_id = wave.uuid"
                      :dark="enrollmentData.enrollment_wave_id === wave.uuid"
                      :color="enrollmentData.enrollment_wave_id === wave.uuid ? wsACCENT : ''"
                      class="pointer pa-3 mt-3 wsRoundedHalf" outlined>
              <h5 class="text-center">{{ wave.name }}</h5>
              <div class="d-flex justify-center">
                <div>
                  <ws-chip icon="mdi-calendar" class="my-1 mr-2" small dark :color="enrollmentData.enrollment_wave_id !== wave.uuid ? wsACCENT : ''"  >
                    <h5 class="font-weight-medium">{{ $t('EducationStart') }}: {{wave.education_start }}</h5>
                  </ws-chip>
                  <ws-chip icon="mdi-calendar" class="my-1 mr-2" small dark :color="enrollmentData.enrollment_wave_id !== wave.uuid ? wsACCENT : ''"  >
                    <h5 class="font-weight-medium">{{ $t('EducationEnd') }}: {{wave.education_end }}</h5>
                  </ws-chip>
                </div>

              </div>

            </v-sheet>
          </div>
        </div>

      </div>



    </wsDialog>

    <!--Auth  Window -->
    <wsDialog v-model="displayAuth"
              width="500"
              :loading="$store.state.LOADING"
              @save="purchaseCourse"
              :title="$t('Authorization')"
              no-navigation
              :show-save="false" >

      <div  class="fill-height d-flex align-center">
        <div style="width: 100%" class="px-3">
          <h5 class="text-center font-weight-light mt-3"> {{ $t('ToContinuePleaseLoginOrRegisterAccount') }}</h5>
          <authWindow @success="authFinished" class="mt-3"
                      display-register
                      :noToggle="true"  />
        </div>
      </div>


    </wsDialog>


  </div>
</template>

<script>
import {mapActions , mapState} from "vuex";
import authWindow from "@/components/auth/authWindow";
import viewButtonCourseEnroll from "@/components/AvalonEditor/View/elements/button/viewButtonCourseEnroll";

export default {
  name: "studentCourseInfo",
  props : ['uuid'],
  components : {
    authWindow,
    viewButtonCourseEnroll
  },
  data() {
    return {
      entity : {},
      enrollmentData : {},
      triggerDisplayEnroll : 0,

      displayEnrollSuccess    :  false,
      displayPurchase : false,
      displayEnroll : false,
      displayAuth : false,
      enrollmentWaves : [],
      displayLogin : true,
      displayApplicationReceivedText : false,
    }
  },
  computed : {
    ...mapState('auth', [  'loggedIn'  ]),

    timePeriodsSelect() {
      if ( !this.entity.time_periods_select) {
        return []
      }
      let items = []
      this.entity.time_periods_select.forEach((period)=>{
        let type = this.entity.time_period_type
        let quantity = type === 'custom' ? (period * this.entity.time_period_days ) : period
        type = type === 'custom' ? 'day' : type

        items.push({
          text : this.$tc(type , quantity),
          value : period
        })
      })
      return items
    },

    purchaseText() {
      return this.HAS_ONLINE_PAYMENT ?  this.$t('Buy') : this.$t('ApplyOnCourse')
    },
    lowestPrice() {
      if ( this.entity.has_payment_plans && this.entity.payment_plans.length > 0  ) {
        let prices = this.entity.payment_plans.map(el => el.price )
        return Math.min(...prices)
      }
      return 0;
    },
    singlePriceText() {

      let price = this.entity.price
      if ( this.entity.has_payment_plans) {
        if (this.enrollmentData.payment_plan_id) {
          let entity = this.entity.payment_plans.find(el => el.value === this.enrollmentData.payment_plan_id)
          price = entity.price
        } else {
          return this.$t('SelectPaymentPlan')
        }
      }

      return price + ' ' + this.BUSINESS_CURRENCY

    },
    totalPriceText() {
      if ( this.entity.has_payment_plans && !this.enrollmentData.payment_plan_id ) {
        return this.$t('SelectPaymentPlan')
      }
      return this.totalPrice + ' ' + this.BUSINESS_CURRENCY
    },
    priceDescriptionSubscriptionText() {
      if ( !this.entity.is_subscription ) {
        return ''
      }

      if ( this.entity.subscription_type === 'time_period' ) {
        if ( this.entity.time_period_type === 'month' ) {
          return ' ' + this.$tc('perMonths' , 1)
        }
        if ( this.entity.time_period_type === 'year' ) {
          return ' ' + this.$tc('perYears' , 1)
        }
        if ( this.entity.time_period_type === 'custom' ) {
          return ' ' + this.$tc('perDays' , this.entity.time_period_days)
        }
      }

      return ''

    },
    totalPrice() {

      let price = this.entity.price
      if ( this.entity.has_payment_plans) {
        if ( this.enrollmentData.payment_plan_id ) {
          let entity = this.entity.payment_plans.find(el => el.value === this.enrollmentData.payment_plan_id)
          price = entity.price
        }
      }

      if ( this.enrollmentData.quantity ) {
        price = price * this.enrollmentData.quantity
      }

      return price

    }

  },
  watch : {
    loggedIn() {
      this.initPage()
    }
  },
  methods : {

    ...mapActions('courses', [  'GET_COURSE_PUBLIC' , 'CHECK_STUDENT_ENROLLMENT', 'GET_COURSE_FULL', 'ENROLL_ON_COURSE' , 'GET_ENROLLMENT_WAVES_STUDENT' ]),
    ...mapActions('cart', [  'ORDER_COURSE'  ]),
    ...mapActions('liqpay', ['GET_BUSINESS_BUTTON']),
    ...mapActions('monobank', ['GET_MONO_CHECKOUT_URL']),
    ...mapActions('fondy', ['GET_FONDY_CHECKOUT_URL']),
    ...mapActions('wayforpay', ['GET_WAYFORPAY_CHECKOUT_URL']),

    async authFinished() {

      let check = await this.CHECK_STUDENT_ENROLLMENT(this.uuid);
      if ( check && check.is_enrolled) {
        this.displayAuth = false
        return this.notify(this.$t('ProfileAlreadyEnrolledOncCourse'))
      }

      this.displayAuth = false
      if ( !this.entity.payed ) {
        this.handleEnrollOnCourse()
      } else {
        this.displayBuyCourse()
      }

    },

    goToCourse() {
      this.$router.push(this.businessDashLink('courses/' + this.uuid + '/student'))
    },
    async displayBuyCourse() {

      if ( !this.loggedIn ) {
        this.displayAuth = true
        return
      }

      if ( this.entity.scheduled ) {
        this.enrollmentData = {
          uuid : this.entity.uuid,
          enrollment_wave_id : null
        }
        this.enrollmentWaves = await this.GET_ENROLLMENT_WAVES_STUDENT(this.uuid) || [] ;
      }
      if ( this.entity.has_payment_plans ) {
        this.enrollmentData.payment_plan_id = null
      }
      this.enrollmentData = JSON.parse(JSON.stringify(this.enrollmentData))

      this.displayPurchase = true
    },
    async purchaseCourse() {

      let data = {
        course_id : this.uuid,
        origin : this.DEVICE
      }

      if (  this.entity.online && this.entity.scheduled ) {
        if ( !this.enrollmentData.enrollment_wave_id ) {
          return this.notify(this.$t('PleaseSelectEnrollmentDate'))
        }
        data.enrollment_wave_id = this.enrollmentData.enrollment_wave_id
      }

      if (  this.entity.payed && this.entity.has_payment_plans ) {
        if ( !this.enrollmentData.payment_plan_id ) {
          return this.notify(this.$t('PleaseSelectPaymentPlan'))
        }
        data.payment_plan_id = this.enrollmentData.payment_plan_id
      }

      if ( this.enrollmentData.quantity ) {
        data.quantity = this.enrollmentData.quantity
      }

      let result = await this.ORDER_COURSE(data)
      if ( !result ) { return this.notify( this.$t('NetworkError') ) }

      this.entity.enrolled = true

      this.processOnlinePayment(result)
      this.displayPurchase = false
      this.displayEnrollSuccess = true;


    },

    async processOnlinePayment(order) {
      if ( !this.HAS_ONLINE_PAYMENT ) {
        this.displayPurchase = false
        this.displayEnrollSuccess = true;
        return
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_liqpay') {
        this.getLiqpayButton(order)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_mono') {
        this.getMonoUrl(order)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_fondy') {
        this.getFondyUrl(order)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_wayforpay') {
        this.getWayforpayUrl(order)
      }
    },

    async getMonoUrl(order) {
      let data = {
        order_number: order.order_number,
        return_url: location.href
      }

      let result = await this.GET_MONO_CHECKOUT_URL(data)
      if ( !result ) {
        return
      }
      let a = document.createElement('a')
      a.href = result.pageUrl
      a.click()
      a.remove()

    },
    async getWayforpayUrl(order) {
      let data = {
        order_number: order.order_number,
        return_url: location.href
      }

      let result = await this.GET_WAYFORPAY_CHECKOUT_URL(data)
      if ( !result ) {
        return
      }
      this.WAYFORPAY_INVOICE(result)
      this.displayEnroll = false
      this.displayEnrollSuccess = true

    },
    async getFondyUrl(order) {
      let data = {
        order_number: order.order_number,
        return_url: location.href
      }

      let result = await this.GET_FONDY_CHECKOUT_URL(data)
      if ( !result ) {
        return
      }
      let a = document.createElement('a')
      a.href = result.url
      a.click()
      a.remove()

    },
    async getLiqpayButton(order) {

      let data = {
        order_number: order.order_number,
        total: this.totalPrice,
        return_url: location.href
      }
      let button = await this.GET_BUSINESS_BUTTON(data)
      if ( !button || button === true ) {
        return this.notify(this.$t('NetworkError'))
      }
      this.LIQPAY_checkout(button)
      this.displayEnroll = false
      this.displayEnrollSuccess = true

    },
    handleEnrollOnCourse() {

      if ( !this.loggedIn ) {
        this.displayAuth = true
        return
      }

      this.enrollmentData = {
        uuid : this.entity.uuid
      }

      if ( !this.entity.online ) {
        this.enrollOnCourse()
      } else {
        this.displayEnrollWindow()
      }

    },
    async displayEnrollWindow() {

      if ( this.entity.online && this.entity.scheduled ) {
        this.enrollmentData = {
          uuid : this.entity.uuid,
          enrollment_wave_id : null
        }
        this.enrollmentWaves = await this.GET_ENROLLMENT_WAVES_STUDENT(this.uuid) || [] ;
      }

      this.displayEnroll = true
    },

    async enrollOnCourse(displayEnroll = true) {


        if ( this.entity.online && this.entity.scheduled ) {
          if ( !this.enrollmentData.enrollment_wave_id ) {
            return this.notify(this.$t('PleaseSelectEnrollmentDate'))
          }
        }

        let result = await this.ENROLL_ON_COURSE(this.enrollmentData);
        if ( !result ) { return this.notify( this.$t('NetworkError') ) }
        this.entity.enrolled = true

      if ( displayEnroll ) {
        this.displayEnroll = false
        this.displayEnrollSuccess = true
      }

    },
    // Technical

    getEntityTypeIcon( type ) {

      let icon = ''
      switch ( type ) {
        case 'lecture'    :  icon = 'mdi-book-open-page-variant'   ; break;
        case 'task'       :  icon = 'mdi-text-box-check-outline'   ; break;
        case 'task_video' :  icon = 'mdi-cast-education'           ; break;
        case 'video'      :  icon = 'mdi-video'                    ; break;
        case 'file'       :  icon = 'mdi-file'                     ; break;
        default : icon = 'mdi-bookmark'
      }
      return icon

    },
    getTopicsLength() {
      let i = 0
      this.entity.modules.forEach((module) => {
        i += module.topics.length
      })
      return i
    },
    getComponentsLength() {
      let i = 0
      this.entity.modules.forEach((module) => {
        module.topics.forEach((topic) => {
          i += topic.components.length
        })

      })
      return i
    },
    async initPage(){
      if ( !this.loggedIn) {
        this.entity = await this.GET_COURSE_PUBLIC(this.uuid) || this.entityDataDefault
      } else {
        this.entity = await this.GET_COURSE_FULL(this.uuid) || this.entityDataDefault
      }
    },
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>