<template>
  <div>
    <v-fade-transition mode="out-in">
      <avalonCourseSingle
          v-if="businessTemplate === 'avalon'"
          key="Avalon"
          :alias="alias"
          :uuid="uuid"/>
    </v-fade-transition>
  </div>
</template>

<script>
import avalonCourseSingle from "@/components/templates/Avalon/UI/avalonCourseSingle";
import {mapState} from "vuex";

export default {
  name: "TemplateLoaderCourseSingle",
  props : ['alias','uuid'],
  components : {
    avalonCourseSingle
  },
  computed : {
    ...mapState('templates', ['businessTemplate']),
  }
}
</script>

<style scoped>

</style>